exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-branding-jsx": () => import("./../../../src/pages/branding.jsx" /* webpackChunkName: "component---src-pages-branding-jsx" */),
  "component---src-pages-case-study-all-elements-jsx": () => import("./../../../src/pages/case-study-all-elements.jsx" /* webpackChunkName: "component---src-pages-case-study-all-elements-jsx" */),
  "component---src-pages-case-study-give-smiles-jsx": () => import("./../../../src/pages/case-study-give-smiles.jsx" /* webpackChunkName: "component---src-pages-case-study-give-smiles-jsx" */),
  "component---src-pages-case-study-kdmsolar-jsx": () => import("./../../../src/pages/case-study-kdmsolar.jsx" /* webpackChunkName: "component---src-pages-case-study-kdmsolar-jsx" */),
  "component---src-pages-case-study-prismalite-jsx": () => import("./../../../src/pages/case-study-prismalite.jsx" /* webpackChunkName: "component---src-pages-case-study-prismalite-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-index-old-jsx": () => import("./../../../src/pages/index-old.jsx" /* webpackChunkName: "component---src-pages-index-old-jsx" */),
  "component---src-pages-landing-page-web-design-jsx": () => import("./../../../src/pages/landing-page-web-design.jsx" /* webpackChunkName: "component---src-pages-landing-page-web-design-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-ux-ui-design-jsx": () => import("./../../../src/pages/ux-ui-design.jsx" /* webpackChunkName: "component---src-pages-ux-ui-design-jsx" */),
  "component---src-pages-web-design-jsx": () => import("./../../../src/pages/web-design.jsx" /* webpackChunkName: "component---src-pages-web-design-jsx" */),
  "component---src-pages-web-design-stoke-jsx": () => import("./../../../src/pages/web-design-stoke.jsx" /* webpackChunkName: "component---src-pages-web-design-stoke-jsx" */)
}

